import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import SolutionImageBox from "@containers/accessibility-solutions/solutions-image-box"
import ContactArea from "@containers/contact-us";
import QuoteForm from "@containers/translation-quote-form";
const AccessibilitySolutionsPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Accessibility Solutions"
        description="Enhance your content's reach with Andovar’s comprehensive accessibility solutions. From inclusive writing to closed captions and audio descriptions, we ensure your media is accessible to everyone regardless of language or disability."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["accessibility-solutions-page-header"]} />
        <QuoteForm />
        <SolutionImageBox data={content["solutions-images-body"]} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query AccessibilitySolutionsPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "accessibility-solutions" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

AccessibilitySolutionsPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default AccessibilitySolutionsPage;
